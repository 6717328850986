const replaceSpacesWithDashes = x => x.replace(/ /g, '-')
const trim = x => x.trim()
const lower = x => x.toLowerCase()
const compose =
  (...fns) =>
    x =>
      fns.reduceRight((v, f) => f(v), x)
const formatState = compose(lower, trim, replaceSpacesWithDashes)

const flippedObject = obj => {
  const flipped = {}
  Object.getOwnPropertyNames(obj).forEach(key => {
    flipped[obj[key]] = key
  })

  return flipped
}

const sanitize = str => {
  let cleanString = str.replace(/%3C.*%3E/ims, '')
  return cleanString.replace(/javascript:.*/ims, '')
}

class ShowOne extends HTMLElement {
  query = this.getAttribute('query')
  history = this.getAttribute('push-history')
  pageTitle = document.querySelector('title').innerText
  dropdown = this.querySelector('[show-select]')
  button = this.querySelector('button.view-selected')

  connectedCallback() {
    if (this.hasAttribute('query-options')) {
      try {
        this.options = JSON.parse(this.getAttribute('query-options'))
      } catch (e) {
        this.options = {}
      }
    } else {
      this.options = {}
    }
    this.optionsInverse = flippedObject(this.options)

    if (this.button) {
      this.button.addEventListener('click', e => {
        let $selectOption = this.dropdown.value.toLowerCase()
        e.preventDefault()

        this.showElement($selectOption)
      })
    } else {
      this.addEventListener('change', this.dispatchChanges)
    }

    const { value } = this.dropdown
    const selected = value.toLowerCase()
    if (value !== 'default') {
      this.showElement(selected)
    }
  }

  showElement(newValue) {
    let $showOptions = []
    const formattedState = formatState(newValue)

    this.querySelectorAll('[show-if]').forEach(el => {
      $showOptions.push(el.getAttribute('show-if'))
      if ($showOptions.indexOf(formattedState) === -1) {
        document.querySelector('.error-panel').style.display = 'block'
        el.hidden = true
      } else {
        document.querySelector('.error-panel').style.display = 'none'
        el.getAttribute('show-if') === formattedState.toLowerCase()
          ? (el.hidden = false)
          : (el.hidden = true)
      }
    })

    const queryValue = this.optionsInverse[this.dropdown.value]
    if (this.query && queryValue && this.history) {
      let hashValue = window.location.hash ? window.location.hash : ''
      hashValue = sanitize(hashValue)
      if (hashValue) {
        history.pushState(
          {},
          this.pageTitle,
          this.history + `/?${this.query}=` + queryValue + hashValue
        )
        window.location.href = hashValue
      } else {
        history.pushState(
          {},
          this.pageTitle,
          this.history + `/?${this.query}=` + queryValue
        )
      }
    }
  }

  dispatchChanges(evt) {
    if (evt.target === this.dropdown) {
      const { value } = evt.target
      this.showElement(value.toLowerCase())
    }
  }
}
customElements.define('show-one', ShowOne)

/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react'

export default function IEModal() {
  const [isShowing, setIsShowing] = useState(true)

  const closeModal = () => {
    sessionStorage.setItem('IE-NOTIFY', JSON.stringify(true))
    document.removeEventListener('keydown', escapeKeyPressed)
    setIsShowing(false)
  }

  const wrapperClick = event => {
    if (event.target.id === 'id-modal-wrapper') {
      closeModal()
    }
  }

  const escapeKeyPressed = event => {
    if (event.code === 'Escape') {
      closeModal()
    }
  }

  document.addEventListener('keydown', escapeKeyPressed)

  if (JSON.parse(sessionStorage.getItem('IE-NOTIFY')) && isShowing) {
    document.removeEventListener('keydown', escapeKeyPressed)
    setIsShowing(false)
  }

  return isShowing ? (
    <>
      <div
        className="Modal"
        role="dialog"
        aria-labelledby="Internet Explorer is no longer supported"
        aria-describedby="ie-modal"
        onClick={wrapperClick}
        id="id-modal-wrapper"
      >
        <section className="Modal-window">
          <header className="Modal-header">
            <h3 className="Modal-title" id="ie-modal-title">
              We noticed you&apos;re using Internet Explorer.
            </h3>
            <button
              className="Modal-icon"
              onClick={closeModal}
              id="ie-modal-close"
            >
              &#x2297;
              <span className="u-hiddenVisually">Close this dialog</span>
            </button>
          </header>
          <div className="Modal-content Content" id="ie-modal-content">
            <p>
              Microsoft recommends using their new Microsoft Edge browser
              instead of Internet Explorer.
            </p>
            <p>
              Ameriprise websites are no longer compatible with the Internet
              Explorer browser which means our website may not display
              correctly.
            </p>
            <div className="ButtonRow">
              <a
                className="Button Button--primary Button--small Link--external"
                href="https://www.microsoft.com/en-us/edge"
                target="_blank"
                rel="noreferrer"
              >
                Download Microsoft Edge
              </a>
              <a
                className="Button Button--secondary Button--secondary--action-blue Button--small"
                href="/client-login/technical-support/"
              >
                See all compatible browsers.
              </a>
            </div>
          </div>
        </section>
      </div>
    </>
  ) : null
}

import React from 'react'
import ReactDOM from 'react-dom/client'
import IEModal from './IEModal.jsx'

class IEModalElement extends HTMLElement {
  connectedCallback() {
    const reactContainerId = 'ie-modal-react-app'
    const reactContainer = document.createElement('div')
    reactContainer.setAttribute('id', reactContainerId)
    this.appendChild(reactContainer)

    const root = ReactDOM.createRoot(document.getElementById(reactContainerId))
    root.render(<IEModal />)
  }
}

function isBrowserInvalid() {
  const sUsrAg = navigator.userAgent

  if (sUsrAg.indexOf('msie') > -1 || sUsrAg.indexOf('Trident') > -1) {
    return true
  } else {
    return false
  }
}

export function init() {
  if (isBrowserInvalid()) {
    customElements.define('ie-modal', IEModalElement)
  }
}

init()

class AssessResult extends HTMLElement {
  get category() {
    return this.hasAttribute('category') ? this.getAttribute('category') : null
  }

  get condition() {
    return this.getAttribute('condition')
  }

  get points() {
    return parseInt(this.getAttribute('points'))
  }

  qualifies(score) {
    const category = this.category
    const condition = this.condition
    const points = this.points

    if (category) {
      if (
        condition === 'greater' &&
        score[category] &&
        score[category] > points
      ) {
        return true
      } else if (
        condition === 'equals' &&
        score[category] &&
        points === score[category]
      ) {
        return true
      }
    } else if (condition === 'greater' && score.__points > points) {
      return true
    } else if (condition === 'equals' && score.__points === points) {
      return true
    }

    return false
  }
}
customElements.define('assess-result', AssessResult)

import { location } from '../../services/getLocation'
import getSearchByZipRandom from '../../services/advisors/getByZip'
import getFaceProfile from '../../services/advisors/getFaceProfile'
import { getAdvisorSearchUrl } from '../../components/Advisor/src/utils'
import { isValidZipcode } from '../../common/utils'

export const newNode = (tag = 'div', opts = {}) =>
  Object.assign(document.createElement(tag), opts)

export const clearNode = node => {
  while (node.firstChild) {
    node.removeChild(node.lastChild)
  }
}

export const newEvent = (
  type = '',
  init = { bubbles: false, cancelable: false, composed: false, detail: null }
) => {
  if (typeof type === 'string' && type.length) {
    init = typeof init === 'object' ? init : {}
    let event
    try {
      event = new CustomEvent(type, init)
    } catch (e) {
      /* IE11 */
      event = document.createEvent(type)
      event.initCustomEvent(
        type,
        init.bubbles || false,
        init.cancelable || false,
        init.detail || null
      )
    }

    return event
  } else {
    console.error(
      `Couldn't create a custom event with a missing 'type' string.`
    )
    return false
  }
}

export const checkFetchUtilData = res => {
  if (res.data) {
    return res.data
  } else {
    throw res.errors
  }
}

export const isValidZIP5Input = s =>
  typeof s === 'string' && /^\d{0,5}$/.test(s)

export const validateZIP5PasteEvent = evt => {
  const text = evt.clipboardData.getData('text')
  if (isValidZIP5Input(text)) {
    return true
  } else {
    evt.preventDefault()
  }
}

export const validateZIP5KeyEvent = evt => {
  if (isValidZIP5Input(evt.key)) {
    return true
  }

  switch (evt.key) {
  case 'Meta':
  case 'Left':
  case 'Right':
  case 'ArrowLeft':
  case 'ArrowRight':
  case 'Backspace':
  case 'Delete':
  case 'Shift':
  case 'Enter':
  case 'Tab':
    return true
  case 'x':
  case 'X':
  case 'y':
  case 'Y':
  case 'z':
  case 'Z':
  case 'c':
  case 'C':
  case 'v':
  case 'V':
    if (evt.metaKey || evt.ctrlKey) {
      return true
    } else {
      evt.preventDefault()
    }
    break
  default:
    evt.preventDefault()
    break
  }
}

export const openZIPSearch = zip => {
  if (isValidZipcode(zip)) {
    window.open(getAdvisorSearchUrl({ zip }), '_blank')
  } else {
    alert('Please enter a valid 5-digit US Postal Code, e.g.: 55474')
  }
}

export const thisComponentStandsAlone = component => {
  const DACs = [...document.querySelectorAll('dynamic-advisor-component')]
  const profiles = [...document.querySelectorAll('advisor-profile')]
  const galleries = [...document.querySelectorAll('advisor-gallery')]

  const dacHasIt = DACs.find(
    dac => dac.contains(component) && dac !== component
  )
  const profileHasIt = profiles.find(
    profile => profile.contains(component) && profile !== component
  )
  const galleryHasIt = galleries.find(
    gallery => gallery.contains(component) && gallery !== component
  )

  return !(dacHasIt || profileHasIt || galleryHasIt)
}

export const loadProfileData = id =>
  getFaceProfile({ id })
    .then(checkFetchUtilData)
    .catch(e => {
      console.error('DAC Profile query failed:', e)
      throw e
    })

export const loadLocationData = () =>
  location()
    .then(checkFetchUtilData)
    .catch(e => {
      console.error('DAC Location query failed:', e)
      throw e
    })

export const loadGalleryData = zip =>
  getSearchByZipRandom({ zip })
    .then(checkFetchUtilData)
    .catch(e => {
      console.error('DAC Advisors query failed:', e)
      throw e
    })
    .then(profiles => {
      if (profiles.length) {
        return profiles
      } else {
        console.error('DAC Advisors query was empty.')
        throw Error('DAC Advisors query was empty.')
      }
    })

import { newAdvisorItem, newMoreAdvisorsItem } from './html'
import {
  newNode,
  clearNode,
  newEvent,
  loadGalleryData,
  validateZIP5KeyEvent,
  validateZIP5PasteEvent,
  openZIPSearch,
  thisComponentStandsAlone
} from './../DynamicAdvisorComponent/utils'

import {
  getStorageZip,
  getUserDataFromStorage
} from '../../services/geoCoordinatesLookup'

class AdvisorGallery extends HTMLElement {
  content = this.querySelector('.dac-content')
  fallback = this.querySelector('.fallback')
  finder = this.querySelector('advisor-finder')
  spinner = this.querySelector('.spinner')
  heading = this.querySelector('.advisors .heading')
  form = this.querySelector('.advisors form')
  search = this.form ? this.form.querySelector('input') : null
  results = this.querySelector('.results')
  isStandalone = thisComponentStandsAlone(this)
  zip = null

  show(show = true) {
    if (typeof show !== 'boolean') {
      console.error(
        'The Advisor Gallery show method received an invalid argument.'
      )
      return false
    }

    this.spinner.hidden = true
    this.content.hidden = !show
    this.fallback.hidden = show
  }

  setLocation(loc) {
    if (typeof loc !== 'object' || !('city' in loc)) {
      this.show(false)
      console.error(
        `The Advisor Gallery location wasn't a properly-formatted object!`
      )
      return false
    }

    const displaying = document.createTextNode('Displaying advisors near ')
    const textContent =
      loc.city.long_name +
      (loc.stateProvince && loc.stateProvince.abbr
        ? `, ${loc.stateProvince.abbr}`
        : '')
    const place = newNode('strong', { textContent })
    clearNode(this.heading)
    this.zip = 'postalCode' in loc ? loc.postalCode : null
    if (this.finder) {
      this.finder.setLocation(loc)
    }
    this.heading.appendChild(displaying)
    this.heading.appendChild(place)
  }

  renderAdvisorsList(profiles) {
    if (!Array.isArray(profiles)) {
      this.show(false)
      console.error(`The Advisor Gallery profiles list wasn't an array!`)
      return false
    }

    const list = newNode('ul', { className: 'u-flex u-sm-flexCol' })
    const previous = this.results.firstElementChild
    profiles.forEach(profile => list.appendChild(newAdvisorItem(profile)))
    list.appendChild(newMoreAdvisorsItem(this.zip))
    this.results.replaceChild(list, previous)
    this.show()
    this.dispatchEvent(newEvent('advisors-loaded'))
  }

  selfStart() {
    this.hidden = false
    new Promise((resolve, reject) => {
      const zip = getStorageZip() || null
      return resolve(zip)
    })
      .then(location => {
        if (location) {
          let userData = getUserDataFromStorage()
          const locData = { postalCode: location }
          userData = {
            ...userData,
            ...{ stateProvince: { abbr: userData.state.short_name } },
            ...locData
          }
          this.setLocation(userData)
          loadGalleryData(this.zip)
            .then(profiles => {
              this.renderAdvisorsList(profiles)
              this.show(true)
            })
            .catch(() => this.show(false))
        }
      })
      .catch(() => this.show(false))
  }

  dispatchKeys(evt) {
    if (evt.target === this.search) {
      validateZIP5KeyEvent(evt)
    }
  }

  dispatchPastes(evt) {
    if (evt.target === this.search) {
      validateZIP5PasteEvent(evt)
    }
  }

  dispatchSubmissions(evt) {
    if (evt.target === this.form) {
      evt.preventDefault()
      openZIPSearch(this.search.value)
    }
  }

  connectedCallback() {
    this.addEventListener('keydown', this.dispatchKeys)
    this.addEventListener('paste', this.dispatchPastes)
    this.addEventListener('submit', this.dispatchSubmissions)

    if (this.isStandalone) {
      this.selfStart()
    }
  }
}

customElements.define('advisor-gallery', AdvisorGallery)

class AssessAnswer extends HTMLElement {
  get isValid() {
    switch (this.type.toLowerCase()) {
    case 'radio':
      return Boolean(this.querySelector(':checked'))
    default:
      return false
    }
  }

  get type() {
    return this.getAttribute('format')
  }

  get points() {
    switch (this.type.toLowerCase()) {
    case 'radio':
      const input = this.querySelector(':checked')
      if (!input) {
        return null
      }
      const cat = input.hasAttribute('category')
      return {
        points: parseInt(input.getAttribute('points')),
        category: cat ? input.getAttribute('category') : null,
        categoryPoints: cat
          ? parseInt(input.getAttribute('category-points'))
          : null
      }
    default:
      return null
    }
  }

  validate() {
    let valid = this.isValid
    if (!valid) {
      this.classList.add('u-textColorAlert')
    } else {
      this.classList.remove('u-textColorAlert')
    }
    return valid
  }
}
customElements.define('assess-answer', AssessAnswer)
